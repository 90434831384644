exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-grocery-tsx": () => import("./../../../src/pages/industries/grocery.tsx" /* webpackChunkName: "component---src-pages-industries-grocery-tsx" */),
  "component---src-pages-industries-universities-tsx": () => import("./../../../src/pages/industries/universities.tsx" /* webpackChunkName: "component---src-pages-industries-universities-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-placeholder-tsx": () => import("./../../../src/pages/placeholder.tsx" /* webpackChunkName: "component---src-pages-placeholder-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

