import 'css-wipe'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from '../styles/GlobalStyle'
import { theme } from '../theme'

export const GlobalLayout: FC = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/rhh0bpi.css" />
      </Helmet>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  )
}
