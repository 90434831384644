import { createGlobalStyle } from 'styled-components'
import { theme } from '../theme'

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  body > #___gatsby,
  #gatsby-focus-wrapper {
    font-size: 16px;
    min-height: 100%;
    height: 100%;
    overflow-x: hidden;
    color: ${theme.typography.color.ink};
    background-color: ${theme.color.appBg};
  }

  a {
    /* text-decoration: underline; */
    color: ${theme.typography.color.ink};
  }

  button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
  }
`
