export const theme = {
  palette: {
    neutral1: '#F5FAFF',
    neutral2: '#EFF5FC',
    neutral3: '#E9F1FA',
    neutral4: '#DAE4F0',
    blue5: '#449AEB',
    blue6: '#2483DB',
    lightYellow: '#FDFCEB', // '#FEFEF8',
    orange: '#F58220',
    orangeDark: '#EF740B'
  },
  color: {
    highlight: '#DF8226',
    black: '#2A2E31',
    appBg: '#fff', // '#FEFCFA',
    divider: 'rgba(0,0,0,0.1)'
  },
  brand: {
    primary: '#DF8226'
  },
  typography: {
    color: {
      ink: '#2A2E31',
      subdued: '#636A73',
      subtle: '#8B96A1',
      reversed: '#fff',
      orange: '#F58220'
    },
    weight: {
      heavy: 900,
      bold: 700,
      semiBold: 500,
      regular: 400
    }
  },
  spacing: {
    extraTight: '4px',
    tight: '8px',
    snug: '12px',
    default: '16px',
    loose: '24px',
    extraLoose: '32px',
    ultraLoose: '48px'
  },
  borderRadius: {
    small: '2px',
    default: '3px',
    large: '6px',
    extraLarge: '12px'
  },
  breakpoint: {
    desktop: '64rem',
    mediumScreen: '48rem',
    smallScreen: '30rem'
  },
  layer: {
    contextLoading: 800,
    appFeedback: 700,
    modal: 600,
    contextFeedback: 500,
    appNavigation: 400,
    contextNavigation: 300,
    fixedContent: 200,
    content: 100,
    first: 1,
    negative: -1
  },
  elevation: {
    masthead: `
      0 1px 4px 0 rgba(0, 0, 0, 0.14),
      0 1px 3px -2px rgba(0, 0, 0, 0.12)
    `,
    content: `
        0 3px 4px 0 rgba(0, 0, 0, 0.14),
        0 3px 3px -2px rgba(0, 0, 0, 0.12),
        0 1px 8px 0 rgba(0, 0, 0, 0.2)
      `,
    contentNavigation: `
        0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 1px 18px 0 rgba(0, 0, 0, 0.12)
      `,
    notification: `
        0 9px 12px 1px rgba(0, 0, 0, 0.14),
        0 5px 6px -3px rgba(0, 0, 0, 0.2),
        0 3px 16px 2px rgba(0, 0, 0, 0.12)
      `,
    modal: `
        0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12),
        0 12px 17px 2px rgba(0, 0, 0, 0.14)
      `
  },
  animation: {
    timing: {
      slow: '300ms',
      default: '120ms',
      fast: '30ms'
    },
    easing: {
      standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
      decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
      accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)'
    }
  },
  dimensions: {
    app: {
      maxWidth: '1200px'
    },
    page: {
      maxWidth: '1200px'
    },
    masthead: {
      height: '72px'
    }
  }
}

export type Theme = typeof theme
